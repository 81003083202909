import * as React from 'react'
import { Link } from 'gatsby'

const PageComponent: React.FC = () => {
  return (
    <div className="error-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="error-content">
              <div className="notfound-404">
                <h1>Nothing</h1>
              </div>
              <h3>404 error - Page not found</h3>
              <p>
                お探しのページは削除されていたり、名前が変更されている可能性があります。
                名前が変更されたり、一時的に利用できなくなっている可能性があります。
              </p>
              <Link to="/" className="btn btn-primary">
                TOPに戻る
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageComponent
